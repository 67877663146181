














































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import {
  DefaBaseButton,
  DefaConfirmation,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import PasswordWithConfirmation from "@/components/forms/PasswordWithConfirmation.vue";
import { httpClient } from "@/http";
import { ROUTE_NAMES } from "@/router/route-names";
import { userModule } from "@/store/modules/user";
import { resetPassword } from "@/http/requests/requests-user";

@Component({
  components: {
    ValidationObserver,
    PasswordWithConfirmation,
    DefaConfirmation,
    DefaTransitionFadeOutIn,
    DefaBaseButton,
  },
})
export default class ViewPasswordRecoveryConfirmation extends Vue {
  @Prop({ default: "" }) readonly hash!: string;
  password = "";
  passwordConfirm = "";
  resettingPassword = false;
  success = false;

  get userModule() {
    return userModule;
  }

  get httpClient() {
    return httpClient;
  }

  get confirmationUrl() {
    return `/users/reset/confirm-hash/${this.hash}`;
  }

  get loginRoute() {
    return {
      name: ROUTE_NAMES.LOGIN,
    };
  }

  onPasswordInput(value: string) {
    this.password = value;
  }

  onPasswordConfirmInput(value: string) {
    this.passwordConfirm = value;
  }

  async resetPassword() {
    this.resettingPassword = true;
    try {
      await resetPassword(this.hash, {
        password: this.password,
      });
      this.success = true;
    } finally {
      this.resettingPassword = false;
    }
  }
}
